import { useEffect, useState } from "react";
import { apiGET } from "../../utilities/apiHelpers";
import Card from "../../components/productComponents/Card";
import MultiRangeSlider from "../../components/RangeSlider/MultiRangeSlider";
import { Accordion, Button, Dropdown, Modal, Pagination } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";
import { FaFilter, FaSearch, FaSortAlphaDown, FaSortAlphaUp, FaSortAmountUp, FaSortNumericDown, FaSortNumericDownAlt } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const ShopPage = () => {
	const [products, setProducts] = useState([])
	const [brands, setBrands] = useState([])
	const [flavors, setFlavors] = useState([])
	const [pots, setPots] = useState([])
	const [productTypes, setProductTypes] = useState([])
	const [page, setPage] = useState(1)
	const [limit, setLimit] = useState(32)
	const [totalResults, setTotalResults] = useState(0)
	const [totalPages, setTotalPages] = useState(0)
	const [loading, setLoading] = useState(false)
	const [brandId, setBrandId] = useState(localStorage.getItem("brandId") || "")
	const [flavor, setFlavor] = useState("")
	const [selectedPot, setSelectedPot] = useState("")
	const [selectedType, setSelectedType] = useState("")

	const navigate = useNavigate()
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [search, setSearch] = useState(queryParams.get("search") || "")

	const [sliderMinValue, setSliderMinValue] = useState(0);
	const [sliderMaxValue, setSliderMaxValue] = useState(50);
	const [minPrice, setMinPrice] = useState(sliderMinValue);
	const [maxPrice, setMaxPrice] = useState(sliderMaxValue);

	const [selectedOption, setSelectedOption] = useState('Newest');
	const [adminSort, setAdminSort] = useState({})

	const sortOptions = [
		{
			key: 'newest',
			value: 'Newest',
		},
		{
			key: 'price-low-to-high',
			value: 'Price (low to high)',
		},
		{
			key: 'price-high-to-low',
			value: 'Price (high to low)',
		},
		{
			key: 'name-a-to-z',
			value: 'Name (A to Z)',
		},
		{
			key: 'name-z-to-a',
			value: 'Name (Z to A)',
		},
		{
			key: 'strength-low-to-high',
			value: 'Strength (low to high)',
		},
		{
			key: 'strength-high-to-low',
			value: 'Strength (high to low)',
		},
	];

	const getAllProducts = async () => {
		try {
			setLoading(true)
			let url = `/v1/products/fetch-products?page=${page}&limit=${limit}`
			let filter = {};
			let sort = {}
			filter['brandId'] = brandId;
			if (minPrice) {
				filter['minPrice'] = minPrice
			}
			if (maxPrice) {
				filter['maxPrice'] = maxPrice
			}
			if (flavor) {
				filter['flavor'] = flavor
			}
			if (selectedPot) {
				filter['pots'] = selectedPot
			}
			if (selectedType) {
				filter['productType'] = selectedType
			}
			if (search) {
				filter['search'] = search
			}
			if (selectedOption) {
				if (selectedOption === "Newest") {
					sort["_id"] = -1
				} else if (selectedOption === "Name (A to Z)") {
					sort["name"] = 1
				} else if (selectedOption === "Price (low to high)") {
					sort["price"] = 1
				} else if (selectedOption === "Price (high to low)") {
					sort["price"] = -1
				} else if (selectedOption === "Name (Z to A)") {
					sort["name"] = -1
				} else if (selectedOption === "Strength (low to high)") {
					sort["strength"] = 1
				} else if (selectedOption === "Strength (high to low)") {
					sort["strength"] = -1
				} else {
					sort["_id"] = -1
				}
			}
			if (Object.keys(filter)?.length > 0) {
				url += `&filter=${JSON.stringify(filter)}`
			}
			if (Object.keys(sort)?.length > 0) {
				url += `&sort=${JSON.stringify(sort)}`
			}
			const response = await apiGET(url);
			setLoading(false)
			if (response?.data?.code === 200) {
				let data = response?.data?.data;
				setProducts(data?.data)
				setTotalResults(data?.totalResults)
				setTotalPages(data?.totalPages)
			} else {
				setProducts([])
				setTotalResults(0)
				setTotalPages(0)
			}
		} catch (error) {
			setProducts([])
			setTotalResults(0)
			setTotalPages(0)
			setLoading(false)
			console.error("Error fetching products :", error);
		}
	}

	const renderPaginationItems = () => {
		const items = [];
		const maxVisiblePages = 5;
		let startPage, endPage;

		if (totalPages <= maxVisiblePages) {
			startPage = 1;
			endPage = totalPages;
		} else {
			const maxPagesBeforeCurrentPage = Math.floor(maxVisiblePages / 2);
			const maxPagesAfterCurrentPage = Math.ceil(maxVisiblePages / 2) - 1;

			if (page <= maxPagesBeforeCurrentPage) {
				startPage = 1;
				endPage = maxVisiblePages;
			} else if (page + maxPagesAfterCurrentPage >= totalPages) {
				startPage = totalPages - maxVisiblePages + 1;
				endPage = totalPages;
			} else {
				startPage = page - maxPagesBeforeCurrentPage;
				endPage = page + maxPagesAfterCurrentPage;
			}
		}

		for (let number = startPage; number <= endPage; number++) {
			items.push(
				<Pagination.Item key={number} active={number === page} onClick={() => handlePageChange(number)}>
					{number}
				</Pagination.Item>,
			);
		}

		return items;
	};

	const handlePageChange = (pageNumber) => {
		if (pageNumber >= 1 && pageNumber <= totalPages) {
			setPage(pageNumber);
			// localStorage.setItem("page", Number(pageNumber))
		}
	};

	const getAllBrands = async () => {
		try {
			const response = await apiGET(`/v1/category/get-category`);
			if (response?.data?.code === 200) {
				setBrands(response?.data?.data);
			} else {
				setBrands([])
				console.error("Error fetching brand list:", response.error);
			}
		} catch (error) {
			setBrands([])
			console.error("Error fetching brand list:", error);
		}
	};

	const getAllFlavours = async () => {
		try {
			const response = await apiGET(`v1/products/getallflavour-name`);
			if (response?.status === 200) {
				setFlavors(response?.data?.data?.data);
			} else {
				setFlavors([])
				console.error("Error fetching flavor list:", response.error);
			}
		} catch (error) {
			setFlavors([])
			console.error("Error fetching flavor list:", error);
		}
	};

	const getPots = async () => {
		try {
			const response = await apiGET(`v1/products/get-number-of-pots`);
			if (response?.status === 200) {
				setPots(response?.data?.data?.data);
			} else {
				setPots([])
				console.error("Error fetching ports list:", response.error);
			}
		} catch (error) {
			setPots([])
			console.error("Error fetching ports list:", error);
		}
	};

	const getAllProductType = async () => {
		try {
			const response = await apiGET(`v1/products/get-all-product-type`);
			if (response?.status === 200) {
				setProductTypes(response?.data?.data?.data);
			} else {
				setProductTypes([])
				console.error("Error fetching product types:", response.error);
			}
		} catch (error) {
			setProductTypes([])
			console.error("Error fetching product types:", error);
		}
	};

	const getAdminsort = async () => {
		try {
			const response = await apiGET(
				`/v1/product-sort/get`
			)
			if (response?.data?.code) {
				let data = response?.data?.data?.data;
				if (data?.sortby === "_id" && data?.sortbyvalue === -1) {
					setSelectedOption("Newest")
				} else if (data?.sortby === "price" && data?.sortbyvalue === 1) {
					setSelectedOption("Price (low to high)")
				} else if (data?.sortby === "price" && data?.sortbyvalue === -1) {
					setSelectedOption("Price (high to low)")
				} else if (data?.sortby === "name" && data?.sortbyvalue === 1) {
					setSelectedOption("Name (A to Z)")
				} else if (data?.sortby === "name" && data?.sortbyvalue === -1) {
					setSelectedOption("Name (Z to A)")
				} else if (data?.sortby === "strength" && data?.sortbyvalue === 1) {
					setSelectedOption("Strength (low to high)")
				} else if (data?.sortby === "strength" && data?.sortbyvalue === -1) {
					setSelectedOption("Strength (high to low)")
				}
				let newSort = {}
				if (data?.sortby) {
					newSort['sortBy'] = data?.sortby
				}
				if (data?.sortbyvalue) {
					newSort['sortByValue'] = data?.sortbyvalue
				}
				if (Object.keys(newSort)?.length === 2) setAdminSort(newSort)
			} else {
				setAdminSort({})
			}
		} catch (error) {
			setAdminSort({})
			console.error("Error fetching sort by:", error);

		}
	}

	function handlePriceRange({ min, max }) {
		setMinPrice(min)
		setMaxPrice(max)
	}


	// Handle item selection
	const handleSelect = (eventKey) => {
		// Find the selected option based on the eventKey
		const selected = sortOptions.find(option => option.key === eventKey);
		if (selected) {
			setSelectedOption(selected.value); // Update the selected option
		}
	};

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [activeKey, setActiveKey] = useState(['0']); // State to manage active accordions

	const handleToggle = (key) => {
		if (activeKey.includes(key)) {
			setActiveKey(activeKey.filter(item => item !== key));
		} else {
			setActiveKey([...activeKey, key]);
		}
	};

	const clearFilter = () => {
		setBrandId("")
		setMinPrice(sliderMinValue)
		setMaxPrice(sliderMaxValue)
		setFlavor("")
		setSelectedPot("")
		setSelectedType("")
		// setPage(1)
		localStorage.setItem("brandId", "")
	}

	const [metaData, setMetaData] = useState([]);
	let fetchSiteMetadata = async () => {
		try {
			let res = await apiGET(`/v1/site-metadata/get-site-metadata-by-type?type=shop page discription`);
			let data = res?.data?.data?.data;
			if (data && data?.length > 0) {
				let statements = data[0]?.statements;
				if (res?.status === 200) {
					setMetaData(statements);
				}
			}
		} catch (error) {
			console.error("Error fetching site metadata:", error);
		}
	};

	useEffect(() => {
		getAllProducts()
		window.scrollTo(0, 0);
	}, [page, brandId, minPrice, search, maxPrice, flavor, selectedPot, selectedType, selectedOption])

	useEffect(() => {
		if (localStorage.getItem("brandId")) {
			setBrandId(localStorage.getItem("brandId"))
		}
		// if (localStorage.getItem("page")) {
		// 	setPage(Number(localStorage.getItem("page")))
		// }
		fetchSiteMetadata()
		getAllBrands()
		getAllProducts()
		getAllFlavours()
		getPots()
		getAllProductType()
		getAdminsort()
	}, [])

	useEffect(() => {
		let queryParams = new URLSearchParams(location.search);
		setSearch(queryParams.get("search") || "")
	}, [location.search])

	return (
		<>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Filter By</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Accordion activeKey={activeKey} alwaysOpen className="shop-accordian">
						<Accordion.Item eventKey="0">
							<Accordion.Header onClick={() => handleToggle('0')}>
								<div className="fs-5 fw-semibold">
									Brands
								</div>
							</Accordion.Header>
							<Accordion.Body>
								{brands?.length ?
									<div className="d-flex flex-column gap-1 ">
										<div className={`cursor-pointer ${brandId === "" ? 'fw-semibold fs-5' : ''}`}
											onClick={() => {
												setBrandId("")
												localStorage.setItem('brandId', "")
											}}
										>
											ALL
										</div>
										{brands.map((item, bId) => (
											<div key={bId}
												className={`cursor-pointer capitalizeText ${brandId === item?._id ? 'fw-semibold fs-5' : ''}`}
												onClick={() => {
													setBrandId(item?._id)
													localStorage.setItem('brandId', item?._id)
												}}
											>
												{item?.name}
											</div>
										))}
									</div>
									:
									<div>No brands</div>
								}
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="1">
							<Accordion.Header onClick={() => handleToggle('1')}>
								<div className="fs-5 fw-semibold">
									Price
								</div>
							</Accordion.Header>
							<Accordion.Body>
								<MultiRangeSlider
									min={sliderMinValue}
									max={sliderMaxValue}
									onChange={handlePriceRange}
								/>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="2">
							<Accordion.Header onClick={() => handleToggle('2')}>
								<div className="fs-5 fw-semibold">
									Flavors
								</div>
							</Accordion.Header>
							<Accordion.Body>
								{flavors?.length ?
									<div className="d-flex flex-column gap-1 ">
										<div className={`cursor-pointer ${flavor === "" ? 'fw-semibold fs-5' : ''}`}
											onClick={() => {
												setFlavor("")
											}}
										>
											ALL
										</div>
										{flavors.map((item, bId) => (
											<div key={bId}
												className={`cursor-pointer capitalizeText ${flavor === item?.flavor ? 'fw-semibold fs-5' : ''}`}
												onClick={() => {
													setFlavor(item?.flavor)
												}}
											>
												{item?.flavor}
											</div>
										))}
									</div>
									:
									<div>No flavors</div>
								}
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="3">
							<Accordion.Header onClick={() => handleToggle('3')}>
								<div className="fs-5 fw-semibold">
									Pots
								</div>
							</Accordion.Header>
							<Accordion.Body>
								{pots?.length ?
									<div className="d-flex flex-column gap-1 ">
										<div className={`cursor-pointer ${selectedPot === "" ? 'fw-semibold fs-5' : ''}`}
											onClick={() => {
												setSelectedPot("")
											}}
										>
											ALL
										</div>
										{pots.map((item, bId) => (
											<div key={bId}
												className={`cursor-pointer ${selectedPot === item?.pots ? 'fw-semibold fs-5' : ''}`}
												onClick={() => {
													setSelectedPot(item?.pots)
												}}
											>
												{item?.pots}
											</div>
										))}
									</div>
									:
									<div>No pots</div>
								}
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="4">
							<Accordion.Header onClick={() => handleToggle('4')}>
								<div className="fs-5 fw-semibold">
									Product Types
								</div>
							</Accordion.Header>
							<Accordion.Body>
								{productTypes?.length ?
									<div className="d-flex flex-column gap-1 ">
										<div className={`cursor-pointer ${selectedType === "" ? 'fw-semibold fs-5' : ''}`}
											onClick={() => {
												setSelectedType("")
											}}
										>
											ALL
										</div>
										{productTypes.map((item, bId) => (
											<div key={bId}
												className={`cursor-pointer ${selectedType === item?.productType ? 'fw-semibold fs-5' : ''}`}
												onClick={() => {
													setSelectedType(item?.productType)
												}}
											>
												{item?.productType}
											</div>
										))}
									</div>
									:
									<div>No product types</div>
								}
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="dark" onClick={handleClose}>
						Close
					</Button>
					<Button variant="secondary" onClick={() => {
						handleClose()
						clearFilter()
					}}>
						<IoCloseSharp /> Clear Filter
					</Button>
				</Modal.Footer>
			</Modal>
			<div className="d-flex justify-content-center w-100 py-5 px-2">
				<Helmet>
					<meta name="description" content={metaData?.length ? metaData : 'Shop our 600+ exiting flavours !'} />
				</Helmet>
				<div className="container">
					<div className="">
						{location?.pathname === "/shop" ? <div className="anton1 fs-2 text-center text-uppercase "
							style={{ letterSpacing: "5px" }}
						>
							Shop All
						</div>
							:
							<div>
								<div className="position-relative">
									<input
										type="text"
										className="w-100 py-1 rounded-2 fs-5 d-flex align-items-center"
										style={{ height: "40px", paddingLeft: "8px", paddingRight: 52 }}
										value={search}
										onChange={(e) => {
											navigate(`/search-results?search=${e.target.value}`)
											setSearch(e.target.value)
										}}
									/>
									<FaSearch
										className="position-absolute fs-4"
										style={{ top: 8, right: "20px", }}
									/>
								</div>
								<div className="mt-2">
									{totalResults} items found for "{search}"
								</div>
							</div>
						}
						<div className="mt-5 d-flex gap-3">
							<div className='d-none d-md-block' style={{ minWidth: '250px' }}>
								<div className="fs-3 fw-semibold" >
									Filter By
								</div>
								<hr />
								<Accordion activeKey={activeKey} alwaysOpen className="shop-accordian">
									<Accordion.Item eventKey="0">
										<Accordion.Header onClick={() => handleToggle('0')}>
											<div className="fs-5 fw-semibold">
												Brands
											</div>
										</Accordion.Header>
										<Accordion.Body>
											{brands?.length ?
												<div className="d-flex flex-column gap-1 ">
													<div className={`cursor-pointer ${brandId === "" ? 'fw-semibold fs-5' : ''}`}
														onClick={() => {
															setBrandId("")
															localStorage.setItem('brandId', "")
														}}
													>
														ALL
													</div>
													{brands.map((item, bId) => (
														<div key={bId}
															className={`cursor-pointer capitalizeText ${brandId === item?._id ? 'fw-semibold fs-5' : ''}`}
															onClick={() => {
																setBrandId(item?._id)
																localStorage.setItem('brandId', item?._id)
															}}
														>
															{item?.name}
														</div>
													))}
												</div>
												:
												<div>No brands</div>
											}
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="1">
										<Accordion.Header onClick={() => handleToggle('1')}>
											<div className="fs-5 fw-semibold">
												Price
											</div>
										</Accordion.Header>
										<Accordion.Body>
											<MultiRangeSlider
												min={sliderMinValue}
												max={sliderMaxValue}
												onChange={handlePriceRange}
											/>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="2">
										<Accordion.Header onClick={() => handleToggle('2')}>
											<div className="fs-5 fw-semibold">
												Flavors
											</div>
										</Accordion.Header>
										<Accordion.Body>
											{flavors?.length ?
												<div className="d-flex flex-column gap-1 ">
													<div className={`cursor-pointer ${flavor === "" ? 'fw-semibold fs-5' : ''}`}
														onClick={() => {
															setFlavor("")
														}}
													>
														ALL
													</div>
													{flavors.map((item, bId) => (
														<div key={bId}
															className={`cursor-pointer capitalizeText ${flavor === item?.flavor ? 'fw-semibold fs-5' : ''}`}
															onClick={() => {
																setFlavor(item?.flavor)
															}}
														>
															{item?.flavor}
														</div>
													))}
												</div>
												:
												<div>No flavors</div>
											}
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="3">
										<Accordion.Header onClick={() => handleToggle('3')}>
											<div className="fs-5 fw-semibold">
												Pots
											</div>
										</Accordion.Header>
										<Accordion.Body>
											{pots?.length ?
												<div className="d-flex flex-column gap-1 ">
													<div className={`cursor-pointer ${selectedPot === "" ? 'fw-semibold fs-5' : ''}`}
														onClick={() => {
															setSelectedPot("")
														}}
													>
														ALL
													</div>
													{pots.map((item, bId) => (
														<div key={bId}
															className={`cursor-pointer ${selectedPot === item?.pots ? 'fw-semibold fs-5' : ''}`}
															onClick={() => {
																setSelectedPot(item?.pots)
															}}
														>
															{item?.pots}
														</div>
													))}
												</div>
												:
												<div>No pots</div>
											}
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="4">
										<Accordion.Header onClick={() => handleToggle('4')}>
											<div className="fs-5 fw-semibold">
												Product Types
											</div>
										</Accordion.Header>
										<Accordion.Body>
											{productTypes?.length ?
												<div className="d-flex flex-column gap-1 ">
													<div className={`cursor-pointer ${selectedType === "" ? 'fw-semibold fs-5' : ''}`}
														onClick={() => {
															setSelectedType("")
														}}
													>
														ALL
													</div>
													{productTypes.map((item, bId) => (
														<div key={bId}
															className={`cursor-pointer ${selectedType === item?.productType ? 'fw-semibold fs-5' : ''}`}
															onClick={() => {
																setSelectedType(item?.productType)
															}}
														>
															{item?.productType}
														</div>
													))}
												</div>
												:
												<div>No pots</div>
											}
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
								<div className="fs-5 fw-semibold p-3 cursor-pointer"
									onClick={clearFilter}
								>
									Clear Filter <IoCloseSharp />
								</div>
							</div>

							<div className="w-100">
								<div className="d-flex justify-content-center gap-3 align-items-center flex-wrap justify-content-md-end mb-3 ">
									<Button
										className="d-block btn-outline-dark btn-light d-md-none"
										onClick={handleShow}
									>
										<FaFilter /> Filter
									</Button>
									<Dropdown className="d-block d-md-none" onSelect={handleSelect}>
										<Dropdown.Toggle type="button" className="btn-outline-dark btn-light btn-md" id="dropdown-secondary">
											{selectedOption === 'Name (Z to A)' ? <FaSortAlphaUp />
												: selectedOption === 'Price (low to high)' || selectedOption === 'Strength (low to high)' ? <FaSortNumericDown />
													: selectedOption === 'Price (high to low)' || selectedOption === 'Strength (high to low)' ? <FaSortNumericDownAlt />
														: selectedOption === 'Name (A to Z)' ? <FaSortAlphaDown />
															: <FaSortAmountUp />} {selectedOption}
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{sortOptions.map(option => (
												<Dropdown.Item key={option.key} eventKey={option.key}>
													{option.value}
												</Dropdown.Item>
											))}
										</Dropdown.Menu>
									</Dropdown>
									<Dropdown className="d-none d-md-block" onSelect={handleSelect}>
										<Dropdown.Toggle type="button" className="btn-outline-dark btn-light btn-lg" id="dropdown-secondary">
											{selectedOption === 'Name (Z to A)' ? <FaSortAlphaUp />
												: selectedOption === 'Price (low to high)' || selectedOption === 'Strength (low to high)' ? <FaSortNumericDown />
													: selectedOption === 'Price (high to low)' || selectedOption === 'Strength (high to low)' ? <FaSortNumericDownAlt />
														: selectedOption === 'Name (A to Z)' ? <FaSortAlphaDown />
															: <FaSortAmountUp />} {selectedOption}
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{sortOptions.map(option => (
												<Dropdown.Item key={option.key} eventKey={option.key}>
													{option.value}
												</Dropdown.Item>
											))}
										</Dropdown.Menu>
									</Dropdown>
								</div>
								{products?.length ?
									<div>
										<div className="row">
											{products.map((item, index) => (
												<div key={index} className="col-6 col-lg-4 col-xl-3">
													<Card productDetail={item} />
												</div>
											))}
										</div>
										<div className="d-flex justify-content-center mt-4">
											<Pagination>
												<Pagination.First onClick={() => handlePageChange(1)} />
												<Pagination.Prev onClick={() => handlePageChange(page - 1)} />
												{renderPaginationItems()}
												<Pagination.Next onClick={() => handlePageChange(page + 1)} />
												<Pagination.Last onClick={() => handlePageChange(totalPages)} />
											</Pagination>
										</div>
									</div>
									:
									loading ?
										<div className="justify-content-center gap-5 fs-3 d-flex align-items-center" style={{ minHeight: "300px" }}>
											<div class="spinner-border text-primary" role="status">
												<span class="visually-hidden">Loading...</span>
											</div>
											Loading Products...
										</div>
										:
										<div className="justify-content-center fs-3 d-flex align-items-center" style={{ minHeight: "300px" }}>
											No Products
										</div>
								}
							</div>
						</div>
					</div>

				</div>
			</div>
		</>
	)
}

export default ShopPage;